<template>
  <div class="code-tab-container">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="(codeTemplate, index) in question.codeQuestionTemplates"
        :key="index"
        :label="codeTemplate.codeType"
        :name="codeTemplate.codeType"
      >
        <template v-if="activeName === codeTemplate.codeType">
          <MonacoEditor
            class="editor"
            ref="monacoEditor"
            :editorId="question.id + codeTemplate.codeType + index + (!readOnly ? 'modify' : '')"
            :value="codeTemplate.template"
            :readOnly="readOnly"
            :language="codeTypes[codeTemplate.codeType]"
            @input="getValue"
          />
        </template>
      </el-tab-pane>
    </el-tabs>
    <div class="tools" v-if="hasTool">
      <div class="tool-button" style="margin-right: 16px" @click="toShowTestCaseModal">
        <YTIcon :href="'#icon-ceshiyongli'"></YTIcon>
        测试用例
      </div>
      <div class="tool-button">
        <YTIcon :href="'#icon-zuoti'"></YTIcon>
        调试
      </div>
    </div>
    <TestCaseMaintainModal ref="testcase-modal" v-model="showTestCaseModal" @refresh="refreshQuestionList" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import MonacoEditor from '@components/common/MonacoEditor'
import TestCaseMaintainModal from '@components/common/old/question_handle/TestCaseMaintainModal'
export default {
  name: 'Code',
  components: { MonacoEditor, YTIcon, TestCaseMaintainModal },
  props: ['question', 'readOnly', 'hasTool'],
  data() {
    return {
      activeName: 'JavaScript',
      codeTypes: {
        java: 'java',
        JavaScript: 'javascript',
        c: 'c',
        'c++': 'cpp',
        python: 'python',
        bash: 'shell'
      },
      showTestCaseModal: false //代码题测试用例
    }
  },
  methods: {
    toShowTestCaseModal() {
      this.$refs['testcase-modal'].setQuestionId(this.question.id)
      this.showTestCaseModal = true
    },
    getValue(value) {
      this.$emit('input', value, this.activeName)
    },
    refreshQuestionList() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.el-tabs {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
::v-deep .el-tabs__header {
  border-bottom: none;
  margin-bottom: 20px;
}
::v-deep .el-tabs__nav {
  border-left: none !important;
  border-top: none !important;
  border-radius: 0 !important;
}
::v-deep .el-tabs__item {
  font-size: @small;
  border-bottom: 1px solid #e9e9e9 !important;
}
::v-deep .is-active {
  border-bottom: none !important;
}
.editor {
  height: 280px;
}
.code-tab-container {
  position: relative;
}
.tools {
  .flexStyle(flex, center, center);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
  .tool-button {
    cursor: pointer;
    &:hover {
      color: #448bff;
    }
  }
}
</style>
