<template>
  <div>
    <el-radio v-model="questionAnswer" :label="true" :disabled="!questionAnswer">正确</el-radio>
    <el-radio v-model="questionAnswer" :label="false" :disabled="questionAnswer">错误</el-radio>
  </div>
</template>

<script>
export default {
  name: 'TrueOrFalse',
  props: ['question'],
  data() {
    return {
      dataType: ['questionDetail'] //获取答案的字段
    }
  },
  computed: {
    questionAnswer() {
      const key = this.dataType.filter(item => {
        return this.question.hasOwnProperty(item)
      })[0]
      const { questionType, answer, questionAnswer } = key ? this.question[key] : this.question
      return this.ytConstant.answerType.getValue(questionType, answer ? answer : questionAnswer)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';
.el-radio {
  margin-right: 50px;
}
::v-deep .el-radio__inner {
  background: #f0f2f3;
  border-color: #bfc1c6;
}
::v-deep .el-radio__label {
  font-size: @small;
  color: #000000 !important;
}
::v-deep.is-checked {
  .el-radio__inner {
    background-color: #448bff;
    &:after {
      display: none;
    }
  }
}
</style>
