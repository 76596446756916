<template>
  <div>
    <div v-for="(option, index) in question.options" :key="index" style="display: flex;margin-bottom: 5px">
      <span style="margin-right: 5px">{{ String.fromCharCode(index + 65) }}.</span>
      <span v-html="option.content"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleChoice',
  props: ['question']
}
</script>

<style lang="less" scoped></style>
